<template>
  <a-modal
    v-model="isShow"
    centered
    :mask-closable="false"
    :ok-button-props="{ props: { loading: submitting } }"
    title="排序"
    width="800px"
    @ok="handleSubmit"
  >
    <a-form class="custom-nested-search-form" :form="form">
      <a-row :gutter="24">
        <a-col :span="6">
          <a-form-item>
            <a-input
                v-decorator="['name', {
                normalize: this.$lodash.trim
              }]"
                placeholder="请输入产品名称"
                allow-clear
                autocomplete="off"
            />
          </a-form-item>
        </a-col>

        <a-col :span="6">
          <a-form-item>
            <a-select
                v-decorator="['product_type']"
                placeholder="产品类型"
                allow-clear
                :loading="typeOptions.length === 0"
            >
              <a-select-option
                  v-for="type in typeOptions"
                  :key="type.slug"
                  :value="type.slug"
              >
                {{ type.name }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>

        <a-col :span="6">
          <a-form-item>
            <a-space>
              <a-button type="primary" @click="handleSortSearch">
                搜索
              </a-button>
              <a-button @click="handleReset">
                刷新
              </a-button>
            </a-space>
          </a-form-item>
        </a-col>
      </a-row>
    </a-form>
    <a-table
      size="middle"
      :columns="columns"
      :data-source="searchData"
      :pagination="false"
      :scroll="{ y: 350 }"
      row-key="id"
    >
      <span slot="actions" slot-scope="text, record">
        <a-space>
          <a @click="top(record.id)">置顶</a>
          <a @click="up(record.id)">上移</a>
          <a @click="down(record.id)">下移</a>
        </a-space>
      </span>
    </a-table>
  </a-modal>
</template>

<script>
import { sort } from '@/api/sort'
import {getProductType} from "@/api/product_type";

export default {
  name: 'SortProduct',
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    data: {
      type: Array,
      default() {
        return []
      }
    },
    tableName: {
      type: String,
      required: true
    },
    columns: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      submitting: false,
      sortData: [],
      searchData: [],
      form: this.$form.createForm(this, { name: 'tenant_product_sort_search' }),
      typeOptions: []
    }
  },
  computed: {
    isShow: {
      get() {
        return this.visible
      },
      set(val) {
        this.$emit('update:visible', val)
      }
    }
  },
  created() {
    this.sortData = this.data
    this.searchData = this.data
    this.getTypeOptions()
  },
  methods: {
    handleReset() {
      this.form.resetFields()
      this.searchData = this.sortData
    },

    handleSortSearch() {
      this.searchData = []
      let search = this.form.getFieldsValue()
      for (const value of this.sortData) {
        if (search.name !== undefined && search.name !== ''){
          if (value.name.indexOf(search.name) === -1){
            continue
          }
        }
        if (search.product_type !== undefined && search.product_type !== ''){
          if (value.product_type !== search.product_type) {
            continue
          }
        }
        this.searchData.push(value)
      }
    },

    top(id) {
      const objIndex = this.$lodash.findIndex(this.sortData, { id: id })
      if (objIndex === 0) {
        this.$warning({
          title: '已置顶',
          content: ''
        })
        return
      }
      const obj = this.sortData[objIndex]
      const newData = [obj]
      for (const item of this.sortData) {
        if (item.id === id) {
          continue
        }
        newData.push(item)
      }
      this.sortData = newData
      this.handleSortSearch()
    },

    up(id) {
      const objIndex = this.$lodash.findIndex(this.sortData, { id: id })
      if (objIndex === 0) {
        this.$warning({
          title: '已置顶无法上移',
          content: ''
        })
        return
      }

      const obj = this.sortData[objIndex]
      const lastObj = this.sortData[objIndex - 1]
      this.$set(this.sortData, objIndex - 1, obj)
      this.$set(this.sortData, objIndex, lastObj)
      this.handleSortSearch()
    },

    down(id) {
      const objIndex = this.$lodash.findIndex(this.sortData, { id: id })
      if (objIndex === this.sortData.length - 1) {
        this.$warning({
          title: '已置底无法下移',
          content: ''
        })
        return
      }
      const obj = this.sortData[objIndex]
      const nextObj = this.sortData[objIndex + 1]
      this.$set(this.sortData, objIndex + 1, obj)
      this.$set(this.sortData, objIndex, nextObj)
      this.handleSortSearch()
    },

    handleSubmit(e) {
      e.preventDefault()
      if (this.submitting) {
        this.$warning({
          title: '请勿重复提交',
          content: ''
        })
        return
      }

      if (!this.sortData || this.sortData.length === 0) {
        this.$warning({
          title: '暂无数据，无法排序',
          content: ''
        })
        return
      }

      this.submitting = true
      const ids = this.$lodash.map(this.sortData, 'id')
      sort({ ids: ids.reverse(), table_name: this.tableName }).then((res) => {
        if (res.code === 0) {
          // 关闭模态框
          this.isShow = false
          // 告知父组件已完成
          this.$emit('completed')
        }
        this.submitting = false
      })
    },

    getTypeOptions() {
      getProductType().then((res) => {
        if (res.code === '0') {
          this.typeOptions = res.data
        }
      })
    }
  }
}
</script>
